<template>
  <section id="invoices">
    <div class="page">
      <app-tabs :tabs="[
        { label: 'Plans', route: { name: 'plans' } },
        { label: 'Invoices', route: { name: 'invoices' } },
        { label: 'Cards', route: { name: 'cards' } }]"
      />

      <div class="dates">
        <app-datepicker
          placeholder="From :"
          insideText="From :"
          :value="dateFrom"
          @input="getInvoicesFrom"
        />
        <app-datepicker
          placeholder="To :"
          insideText="To :"
          :value="dateTo"
          @input="getInvoicesTo"
        />
      </div>

      <div v-if="isLoading" class="grid-x loading">
        <div class="cell auto" />
        <div class="cell auto">
          <app-spinner color="primary" />
        </div>
        <div class="cell auto" />
      </div>

      <div v-else-if="invoices.data && invoices.data.length || dateFrom || dateTo" class="invoices">
        <app-table
          :headers="tableHeader"
          :data="invoices.data"
          :loading="isLoading"
        >
          <template slot="loading">
            <app-spinner/>
          </template>
          <template slot="id" slot-scope="{ data }">
            <p>{{ data.number }}</p>
          </template>
          <template slot="date" slot-scope="{ data }">
            <p>{{ $dayjs.unix(data.created).format('MMMM D, YYYY') }}</p>
          </template>
          <template slot="amount" slot-scope="{ data }">
            <p class="price">{{ (data.total / 100).toFixed(2) }} €</p>
          </template>
          <template slot="download" slot-scope="{ data }">
            <app-button @click="download(data.invoice_pdf)">
              <download />
            </app-button>
          </template>
          <template slot="empty-table">
            <p>No invoices found !</p>
          </template>
        </app-table>
      </div>

      <div v-else class="grid-x no-invoices">
        <div class="cell auto"></div>
        <div class="cell auto">
          <no-invoice />
          <p>No invoices available for now.</p>
        </div>
        <div class="cell auto"></div>
      </div>
    </div>
  </section>
</template>

<script>
import stripeApi from '@/services/api/stripe';
import noInvoice from '@/assets/img/no-invoice.svg?inline';
import download from '@/assets/img/download.svg?inline';

export default {
  name: 'plans',

  components: {
    'no-invoice': noInvoice,
    download,
  },

  data() {
    return {
      tableHeader: [
        { label: 'Invoice number', key: 'id' },
        { label: 'Date of issue', key: 'date' },
        { label: 'Amount', key: 'amount' },
        { label: '', key: 'download', size: 'small-1' },
      ],
      invoices: {
        data: [],
        metadata: {
          limit: 10,
          hasMore: null,
        },
      },
      isLoading: null,

      dateFrom: null,
      dateTo: null,

      pagination: null,
    };
  },

  mounted() {
    const { from, to } = this.$route.query;

    if (from) {
      this.dateFrom = new Date(from);
    }

    if (to) {
      this.dateTo = new Date(to);
    }

    this.getData();
  },

  watch: {
    async $route() {
      if (!this.isLoading) {
        this.isLoading = true;
        await this.getInvoices();
        this.isLoading = false;
      }
    },
  },

  methods: {
    async getData() {
      await this.getInvoices();
    },

    async getInvoices() {
      try {
        this.isLoading = true;

        const from = this.dateFrom ? this.$dayjs(this.dateFrom).unix() : null;
        const to = this.dateTo ? this.$dayjs(this.dateTo).unix() : null;

        let next = null;
        let prev = null;
        if (this.pagination === 'next') {
          next = this.invoices.data[this.invoices.data.length - 1].id;
        } else if (this.pagination === 'previous') {
          prev = this.invoices.data[0].id;
        }

        this.invoices = await stripeApi.getInvoices(this.invoices.metadata.limit, from, to, next, prev);
        this.isLoading = false;
      } catch (er) {
        this.$message.show({
          title: 'Erreur',
          text: 'Impossible to find your invoices',
          confirmText: 'Ok',
          hasCancel: false,
        });
        this.isLoading = false;
      }
    },

    download(url) {
      window.location = url;
    },

    previousPage() {
      if (!this.isFirstPage) {
        this.pagination = 'previous';
        this.getInvoices();
      }
    },

    nextPage() {
      if (!this.isLastPage) {
        this.pagination = 'next';
        this.getInvoices();
      }
    },

    updateFiltersQuery() {
      this.$router.push({
        query: {
          ...this.$route.query,
          from: this.dateFrom ? this.dateFrom.toISOString() : undefined,
          to: this.dateTo ? this.dateTo.toISOString() : undefined,
        },
      });
    },

    getInvoicesFrom(from) {
      this.dateFrom = from;
      this.updateFiltersQuery();
    },

    getInvoicesTo(to) {
      this.dateTo = to;
      this.updateFiltersQuery();
    },
  },
};
</script>

<style lang="sass">
#invoices

  .page
    @include page

    h1
      color: $white
      margin-bottom: 30px

      svg
        margin-right: 10px

    .dates
      display: flex
      justify-content: flex-end
      margin-top: 32px

      .app-datepicker
        margin-left: 8px
        width: fit-content

    .invoices

      .app-table

        .data

          .price
            @include label-bold

          .app-button
            display: flex
            align-items: center
            justify-content: center
            width: 40px
            height: 40px
            margin: 0
            padding: 0
          .cell:last-child
            justify-content: right

      .pagination

        .cell

          svg
            margin: 0 8px

            &:hover
              cursor: pointer

            &.disabled
              cursor: initial

    .no-invoices, .loading
      max-width: 1000px
      margin-top: 50px

      .cell
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center

      p
        margin-top: 16px
</style>
