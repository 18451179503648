var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{attrs:{"id":"invoices"}},[_c('div',{staticClass:"page"},[_c('app-tabs',{attrs:{"tabs":[
      { label: 'Plans', route: { name: 'plans' } },
      { label: 'Invoices', route: { name: 'invoices' } },
      { label: 'Cards', route: { name: 'cards' } }]}}),_c('div',{staticClass:"dates"},[_c('app-datepicker',{attrs:{"placeholder":"From :","insideText":"From :","value":_vm.dateFrom},on:{"input":_vm.getInvoicesFrom}}),_c('app-datepicker',{attrs:{"placeholder":"To :","insideText":"To :","value":_vm.dateTo},on:{"input":_vm.getInvoicesTo}})],1),(_vm.isLoading)?_c('div',{staticClass:"grid-x loading"},[_c('div',{staticClass:"cell auto"}),_c('div',{staticClass:"cell auto"},[_c('app-spinner',{attrs:{"color":"primary"}})],1),_c('div',{staticClass:"cell auto"})]):(_vm.invoices.data && _vm.invoices.data.length || _vm.dateFrom || _vm.dateTo)?_c('div',{staticClass:"invoices"},[_c('app-table',{attrs:{"headers":_vm.tableHeader,"data":_vm.invoices.data,"loading":_vm.isLoading},scopedSlots:_vm._u([{key:"id",fn:function(ref){
      var data = ref.data;
return [_c('p',[_vm._v(_vm._s(data.number))])]}},{key:"date",fn:function(ref){
      var data = ref.data;
return [_c('p',[_vm._v(_vm._s(_vm.$dayjs.unix(data.created).format('MMMM D, YYYY')))])]}},{key:"amount",fn:function(ref){
      var data = ref.data;
return [_c('p',{staticClass:"price"},[_vm._v(_vm._s((data.total / 100).toFixed(2))+" €")])]}},{key:"download",fn:function(ref){
      var data = ref.data;
return [_c('app-button',{on:{"click":function($event){return _vm.download(data.invoice_pdf)}}},[_c('download')],1)]}}])},[_c('template',{slot:"loading"},[_c('app-spinner')],1),_c('template',{slot:"empty-table"},[_c('p',[_vm._v("No invoices found !")])])],2)],1):_c('div',{staticClass:"grid-x no-invoices"},[_c('div',{staticClass:"cell auto"}),_c('div',{staticClass:"cell auto"},[_c('no-invoice'),_c('p',[_vm._v("No invoices available for now.")])],1),_c('div',{staticClass:"cell auto"})])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }